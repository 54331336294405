<template>
    <div class="code">
        <img src="../assets/image/new_return.png" alt="" class="go-back" @click="handleGoBack">
        <div class="box">
            <div class="title">推荐方案信息</div>
            <div class="plan-title">推荐方案标题</div>
            <div class="input-content">
                <input type="text" v-model="title" class="phone-url" placeholder="填写方案标题">
                <div class="btn" @click="handleWriteTitle">确定</div>
            </div>
            <div class="plan-title">联系方式</div>
            <div class="input-content">
                <input type="number" v-model="phone" class="phone-url" placeholder="填写联系方式" @focus="handleChangePhone">
                <div class="btn" @click="handleWrite">{{phoneText}}</div>
            </div>
            <div class="plan-title">分享</div>
            <div class="input-content">
                <input type="text" v-model="url" class="phone-url" disabled :title="url">
                <div class="btn copyData" @click="handleCopy">复制链接</div>
            </div>
            <img :src="codeUrl" alt="" class="code-img">
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: "",
                phoneText: "自动填充",
                phone: "",
                codeUrl: "",
                codeData: {},
                panorama_id: "",
                url: "",
                beforePage: false
            }
        },
        beforeRouterEnter(to, from, next) {
            if (from.name == null) {
                next(vm => {
                    vm.beforePage = true
                })
            }else{
                next()
            }
        },
        methods: {
            handleGoBack() {
                if (!this.beforePage) {
                    this.$router.go(-1)
                } else {
                    this.$router.push({
                        path: "/"
                    })
                }
            },
            getData() {
                this.$axios({
                    method: "POST",
                    url: "api/v5/panorama/sharePanorama",
                    data: {
                        panorama_id: this.$route.params.panorama,
                        rent_id: this.$route.params.rent.toString(),
                    },
                    headers: {
                        "panorama-api-token": sessionStorage.getItem("token")
                    }
                }).then(res => {
                    if (res.data.status == '200') {
                        this.title = res.data.data.name;
                        this.codeData = res.data.data;
                        this.codeUrl = res.data.data.QR_code;
                        this.url = res.data.data.url;
                        this.panorama_id = res.data.data.panorama_id
                    }
                })
            },
            //自动填充
            handleWrite() {
                if (this.phoneText == '确定') {
                    const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
                    if (reg.test(this.phone)) {
                        this.$axios({
                            method: "post",
                            url: "api/v5/panorama/perfectBook",
                            data: {
                                panorama_id: this.panorama_id,
                                phone: this.phone,
                                title: this.title
                            },
                            headers: {
                                "panorama-api-token": sessionStorage.getItem("token")
                            }
                        }).then(res => {
                            if (res.data.status == '200') {
                                this.phoneText = "自动填充"
                            }
                        })
                    } else {
                        alert('请输入正确的手机号')
                    }
                } else {
                    this.phone = this.codeData.phone;
                    this.$axios({
                        method: "post",
                        url: "api/v5/panorama/perfectBook",
                        data: {
                            panorama_id: this.panorama_id,
                            phone: this.phone,
                            title: this.title
                        },
                        headers: {
                            "panorama-api-token": sessionStorage.getItem("token")
                        }
                    }).then(res => {
                        if (res.data.status == '200') {
                            this.phoneText = "自动填充"
                        }
                    })
                }
            },
            //复制链接
            handleCopy() {
                const input = document.createElement('input')
                document.body.appendChild(input)
                input.setAttribute('value', this.url)
                input.select()
                if (document.execCommand('copy')) {
                    alert('复制成功')
                    document.execCommand('copy')
                }
                document.body.removeChild(input)
            },
            handleChangePhone() {
                this.phoneText = '确定'

            },
            handleWriteTitle() {
                this.$axios({
                    method: "post",
                    url: "api/v5/panorama/perfectBook",
                    data: {
                        panorama_id: this.panorama_id,
                        phone: this.phone,
                        title: this.title
                    },
                    headers: {
                        "panorama-api-token": sessionStorage.getItem("token")
                    }
                }).then(res => {

                })
            },
        },
        created() {
            this.getData()
        },
    }
</script>
<style lang='scss' scoped>
    .code {
        display: flex;
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background: url('../assets/image/bg.jpg') no-repeat;
        background-size: 100% 100%;

        .go-back {
            position: absolute;
            width: 44px;
            height: 36px;
            top: 77px;
            left: 72px;
            cursor: pointer;
            z-index: 2;
        }

        .box {
            padding: 0 86px;
            width: 834px;
            height: 900px;
            background: #fff;

            .title {
                margin: 104px 0 40px;
                font-size: 40px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                line-height: 49px;
            }

            .plan-title {
                margin: 32px 0 14px 0;
                font-size: 27px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 49px;
            }

            .input-content {
                display: flex;
                align-items: center;

                .phone-url {
                    width: 75%;
                    height: 70px;
                    text-align: center;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .btn {
                    width: 25%;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    background: #333;
                    cursor: pointer;
                }
            }

            .code-img {
                margin-top: 64px;
                width: 137px;
                height: 137px;
            }
        }
    }
</style>